<template>
  <Logo />
  <!-- <b-img :src="appLogoImage" alt="logo" style="height: 50px" /> -->
</template>
<script>
import { $themeConfig } from "@themeConfig";
import Logo from "@/assets/images/logo/logo.svg";
export default {
  components: {
    // BImg,
    Logo,
  },
  data: function () {
    return { appLogoImage: $themeConfig.app.appLogoImage };
  },
};
</script>
